import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.sidebar.appinfo');
import { OnInit, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-dizest-sidebar-appinfo',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.dizest.sidebar.appinfo/view.scss */
.workspace-tab {
  height: 100%;
  width: 550px;
  display: flex;
  flex-direction: column;
  background: #fff;
  background: var(--wc-background);
  border-left: 1px solid var(--wc-border);
}
.workspace-tab .workspace-tab-header {
  display: flex;
  background: var(--wc-blue);
  padding: 4px 12px;
  height: 40px;
  align-items: center;
}
.workspace-tab .workspace-tab-header .workspace-tab-header-title {
  color: white;
  font-family: "wiz-eb";
  font-size: 16px;
}
.workspace-tab .workspace-tab-header .close-btn {
  font-size: 16px;
  cursor: pointer;
  --tblr-btn-close-opacity: 0.9;
}
.workspace-tab .workspace-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
}
.workspace-tab .workspace-tab-body {
  padding: 16px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: auto;
  padding-bottom: 40vh;
}

.featured-image {
  max-width: 100%;
  max-height: 280px;
}

.hover-group {
  top: 20px;
  right: 20px;
}
.hover-group button {
  width: 50px;
  height: 50px;
  margin-left: auto;
}
.hover-group button span {
  display: none;
}
.hover-group button:hover {
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.hover-group button:hover span {
  display: inline;
}

.card-body .avatar-area .avatar-container .avatar {
  width: 150px;
  height: 150px;
}
.card-body .log {
  padding-top: 4px;
  margin: 4px 0;
}
.card-body .log + .log {
  border-top: 1px solid lightgray;
}

.row.mx-0 .col-md-3.d-flex {
  line-height: 36px;
}`],
})
export class PortalDizestSidebarAppinfoComponent implements OnInit, OnDestroy {
    @Input() config = {};
    public data: any = {};
    public style: any = {};

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.data = this.config.activeApp.spec();
        this.style.image = {
            backgroundImage: `url(${this.data.image})`,
        };
        await this.service.render();
    }

    public ngOnDestroy() {
        try {
            this.logSource.close();
        } catch { }
    }

    public close() {
        this.config.activeApp = false;
        this.service.render();
    }

    public _type(item) {
        if (item._type === "str") return "text";
        else return "number";
    }

    public flow() {
        return this.config.workflows.active.flow;
    }

    // public flowdata() {
    //     const flow = this.flow();
    //     const flow_id = flow.selected;
    //     return flow.workflow.data.flow[flow_id].data;
    // }

    public flowdata() {
        const flow = this.flow();

        if (!flow) {
            console.error('Flow is undefined');
            return {}; // 또는 적절한 기본값을 반환
        }

        const flow_id = flow.selected;

        if (flow_id === undefined) {
            console.error('Flow ID is undefined');
            return {}; // 또는 적절한 기본값을 반환
        }

        const flowData = flow.workflow?.data?.flow?.[flow_id]?.data;

        if (!flowData) {
            console.warn('Flow data is undefined or does not exist for the given flow_id');
            return {}; // 적절한 기본값을 반환하거나 null 등을 반환
        }

        return flowData;
    }



    public log() {
        const flow = this.flow();
        const flow_id = flow.selected;
        return flow.get(flow_id).log();
    }

    public delete() {
        const flow = this.flow();
        const flow_id = flow.selected;
        flow.get(flow_id).delete();
        this.close();
    }
}

export default PortalDizestSidebarAppinfoComponent;