import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.app.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { AnsiUp } from 'ansi_up';

@Component({
    selector: 'wiz-portal-krdc-app-list',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.app.list/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}

table td {
  width: auto;
}

.col-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.log-list {
  max-height: 600px;
}

.badge {
  line-height: 20px;
}

textarea {
  resize: none;
}`],
})
export class PortalKrdcAppListComponent implements OnInit {
    public ansiUp = new AnsiUp();
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagination: any = {
        end: -1,
        start: 0,
    }
    public apps = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
        this.ansiUp.use_classes = true;
        await this.getArgoUrl();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        const { code, data } = await wiz.call("load", this.search);
        if (code !== 200) return await this.service.error("앱 조회 실패!");
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.apps = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        await this.service.render();
    }

    public showChecked() {
        return this.apps.filter(it => it.checked).length > 0;
    }

    public remove = null;

    public showRemove(item) {
        if (item.status === "pending") return;
        this.remove = [item];
        this.service.render();
    }

    public async removeItem() {
        const body = {
            resource_type: "app",
            resource_id: this.remove.map(it => it.id).join(","),
        };
        const { code } = await this.service.api("resources/delete", body);
        if (code !== 200) return await this.service.error("앱 삭제 실패!");
        await this.service.success("앱 삭제 성공!");
        this.remove = null;
        await this.load(this.search.page);
    }

    public async removeItems() {
        this.remove = this.service.copy(this.apps.filter(it => it.checked));
        await this.service.render();
    }

    public deploy = null;
    public access = "internal";
    public log = "";
    public deployLogs = [];

    public async showDeploy(item) {
        if (!["created", "rejected"].includes(item.status)) return;
        this.deployLogs = [];
        this.deploy = item.id;
        this.access = "internal";
        this.log = "";
        this.service.render();
        const { code, data } = await this.service.api("resources/deploy/log", { resource_id: item.id });
        if (code === 200) {
            this.deployLogs = data;
            this.service.render();
        }
    }

    public async deployItem() {
        const body = {
            resource_type: "app",
            resource_id: this.deploy,
            request: this.log,
            access: this.access,
        };
        const { code } = await this.service.api("resources/deploy", body);
        if (code !== 200) return await this.service.error("앱 배포 요청 실패!");
        await this.service.success("앱 배포 요청 성공!");
        this.deploy = null;
        this.log = null;
        this.access = null;
        await this.load(this.search.page)
    }

    public async rebuild(item) {
        if (item.status === "pending") return;
        let message = `앱 빌드를 실행하시겠습니까?`;
        if (item.extra.workflow_name) message = `빌드 실행 이력이 존재합니다.\n앱 빌드를 다시 실행하시겠습니까?`;
        const res = await this.service.alert.show({
            title: "앱 빌드",
            message,
            action: "빌드",
            status: "success",
            actionBtn: "success",
            cancel: "취소",
        });
        if (!res) return;
        await this.service.loading.show();
        const body = {
            resource_type: "app",
            id: item.id,
        };
        const { code } = await this.service.api("resources/build", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("앱 빌드 요청 실패!");
        await this.service.success("앱 빌드 요청 성공!");
        await this.load(this.search.page);
    }

    public ansiHTML(txt) {
        return this.ansiUp.ansi_to_html(txt);
    }

    public argo_base = "";
    public async getArgoUrl() {
        const { code, data } = await wiz.call("argo");
        if (code !== 200) return;
        this.argo_base = data;
    }

    public watch_log = null;
    public logs = [];
    public async watch(item) {
        this.logs = [];
        const workflow_name = item.extra.workflow_name;
        if (!workflow_name) return;
        this.watch_log = item;
        await this.service.render();
        const source = new EventSource(`${this.argo_base}/${workflow_name}/log?logOptions.container=main&grep=&logOptions.follow=true`);
        source.onmessage = async (e) => {
            try {
                const data = e.data.trim().split("\n")
                    .map(it => {
                        let text = "";
                        try {
                            const obj = JSON.parse(it);
                            text = obj.result.content;
                        } catch { }
                        return text;
                    });
                this.logs.push(...data);
                await this.service.render();
                document.querySelector(".log-list").lastElementChild.scrollIntoView();
            } catch { }
        }
        source.onerror = () => {
            source.close();
        }
    }

    public async deleteLog() {
        const id = this.watch_log.id;
        const workflow_name = this.watch_log.extra.workflow_name;
        const body = { id, workflow_name };
        const { code } = await wiz.call("delete_log", body);
        if (code !== 200) return await this.service.error("로그 삭제 실패!");
        await this.service.success("로그 삭제 성공!");
        this.logs = [];
        this.watch_log = null;
        await this.service.render();
        await this.load(this.search.page);
    }

    public edit = false;
    public showEdit(item) {
        this.edit = this.service.copy(item);
        this.service.render();
    }
    public async saveEdit() {
        if (!this.edit) return;
        const body = {
            id: this.edit.id,
            description: this.edit.description,
        }
        const { code } = await wiz.call("edit", body);
        if (code !== 200) return await this.service.error("정보 수정 실패!");
        await this.service.success("정보 수정 성공!");
        this.edit = false;
        await this.service.render();
        await this.load();
    }
}

export default PortalKrdcAppListComponent;