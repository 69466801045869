import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.mypage/view.scss */
.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.7), transparent), url("/assets/background/private.jpg");
}

.content {
  padding-top: 24px;
}
.content.view-max {
  min-height: 100%;
}
.content.content-light {
  background-color: #E9E8EE;
}

.sticky-menu {
  display: flex;
  align-items: center;
  scrollbar-width: none;
  position: sticky !important;
  z-index: 999999 !important;
}
.sticky-menu .btn {
  height: 45px;
}
.sticky-menu .search-input {
  margin: 0 4px;
}
.sticky-menu .search-input .form-control {
  width: 220px;
  height: 45px;
}

.round-0 {
  border-radius: 0 !important;
}

.content-container {
  position: relative;
  z-index: 0;
}

.card {
  border-radius: 4px;
}
.card.card-info {
  background: #F0F1F3;
  border: none;
  box-shadow: none;
}
.card.card-info .mb-2 {
  display: flex;
}
.card.card-info .card-title {
  font-weight: 1000;
}
.card.card-info i {
  width: 20px;
  text-align: center;
  margin-right: 8px;
  flex: none;
}
.card.card-info .info-title {
  font-weight: 600;
  width: 96px;
  flex: none;
}
.card.card-info .info-content {
  flex: auto;
  text-align: right;
}

.alert h4 {
  font-size: 18px;
  margin-bottom: 12px;
}
.alert ol {
  font-size: 16px;
  line-height: 1.3;
}

h2.title.cursor-pointer:hover {
  color: var(--wc-red);
}

.info-context {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}
.info-context .form-control,
.info-context .form-select {
  box-shadow: none;
  text-align: left;
}
.info-context:last-child {
  margin-bottom: 0;
}
.info-context .td {
  flex: 1;
}
.info-context .th {
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  font-family: "main-eb";
  padding-right: 12px;
}

.main-container {
  padding: 24px 32px;
  width: 100%;
  max-width: 1160px;
  background-color: #F0F1F3;
  border-radius: 24px;
  margin-bottom: 42px;
}
.main-container .title {
  font-family: SUIT;
  font-size: 24px;
  font-weight: 1000;
  margin-bottom: 16px;
}
.main-container .form-selectgroup-label {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.main-container .form-control,
.main-container .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
  flex: 1;
}
.main-container .tag {
  display: inline-block;
  padding: 0 12px;
}
.main-container .tag > * {
  display: inline-block;
}

.featured-image .featured-text {
  display: none;
}
.featured-image:hover .featured-text {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.featured-image:hover .featured-text i:hover {
  color: var(--wc-red);
}

.info-context img {
  max-width: 320px;
}`],
})
export class PageMypageComponent implements OnInit {
    public data: any;
    public passwd: any = {};
    public loaded = false;

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/main");
        await this.service.loading.show();
        await this.load();
        if (["developer", "admin"].includes(this.service.auth.session.role)) {
            await this.loadStorage();
            await this.loadGitlab();
        }
        this.loaded = true;
        await this.service.loading.hide();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) return await this.service.error("SERVER ERROR");
        this.data = data;
        await this.service.render();
    }

    public roleMap = {
        user: "일반사용자",
        developer: "앱 제공자",
        admin: "클러스터 관리자",
    };

    public ts2str(ts) {
        return new Date(+ts).format("yyyy.MM.dd HH:mm:ss");
    }

    public storage = {
        exists: null,
    };

    public gitlab = {
        exists: null,
    };

    public async loadStorage() {
        const { code, data } = await this.service.api("storages/exists");
        if (code !== 200) return await this.service.error("스토리지 서버에 에러가 발생하였습니다. 관리자에게 문의하세요.");
        this.storage.exists = data;
        await this.service.render();
    }
    public async openStorage() {
        const { code, data } = await wiz.call("storage_url");
        if (code !== 200) return await this.service.error("스토리지 서버에 에러가 발생하였습니다. 관리자에게 문의하세요.");
        window.open(data, "_blank");
    }
    public async createStorage() {
        await this.service.loading.show();
        const { code } = await this.service.api("storages/create", { kind: "personal" });
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("사용자 스토리지 생성에 실패하였습니다. 관리자에게 문의하세요.");
        await this.service.success("사용자 스토리지 생성에 성공하였습니다.");
        location.reload();
    }

    public async loadGitlab() {
        const { code, data } = await wiz.call("load_gitlab");
        if (code !== 200) return await this.service.error("깃랩 로드 에러가 발생하였습니다. 관리자에게 문의하세요.");
        this.gitlab.exists = data;
        await this.service.render();
    }
    public async initGitlab() {
        const { code, data } = await wiz.call("init_gitlab");
        if (code !== 200) return await this.service.error("계정 초기화에 에러가 발생하였습니다. 관리자에게 문의하세요.");
        await this.service.success("초기화 비밀번호: " + data)
        location.reload();
    }
    public async createGitlab() {
        await this.service.loading.show();
        const { code, data } = await wiz.call("create_gitlab");
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("사용자 계정 생성에 실패하였습니다. 관리자에게 문의하세요.");
        await this.service.success("사용자 계정 생성에 성공하였습니다. \n초기 비밀번호: " + data);
        location.reload();
    }

    public async update() {
        const body = {
            firstName: this.data.firstName,
            lastName: this.data.lastName,
        };
        await this.service.loading.show();
        const { code } = await wiz.call("update", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("회원 정보 변경 중 에러가 발생하였습니다.");
        await this.service.success("회원 정보가 변경되었습니다.");
    }
}

export default PageMypageComponent;