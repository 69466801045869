import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.workflow');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-workflow',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.workflow/view.scss */
/* src/app/page.workflow/view.scss: no such file or directory */`],
})
export class PageWorkflowComponent implements OnInit, DoCheck {
    public category = "";

    constructor(@Inject( Service) public service: Service) {
        if (!WizRoute.segment.category)
            return service.href("/workflow/develop");
        this.category = WizRoute.segment.category;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/main");
    }

    public async ngDoCheck() {
        if (!WizRoute.segment.category)
            return this.service.href("/workflow/develop");
        this.category = WizRoute.segment.category;
    }

}

export default PageWorkflowComponent;