import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.sidebar.workflowinfo');
import { OnInit, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';
import $ from 'jquery';

@Component({
    selector: 'wiz-portal-dizest-sidebar-workflowinfo',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.dizest.sidebar.workflowinfo/view.scss */
.workspace-tab {
  height: 100%;
  width: 550px;
  display: flex;
  flex-direction: column;
  background: #fff;
  background: var(--wc-background);
  border-left: 1px solid var(--wc-border);
}
.workspace-tab .workspace-tab-header {
  display: flex;
  background: var(--wc-blue);
  padding: 4px 12px;
  height: 40px;
  align-items: center;
}
.workspace-tab .workspace-tab-header .workspace-tab-header-title {
  color: white;
  font-family: "wiz-eb";
  font-size: 16px;
}
.workspace-tab .workspace-tab-header .close-btn {
  font-size: 16px;
  cursor: pointer;
  --tblr-btn-close-opacity: 0.9;
}
.workspace-tab .workspace-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
}
.workspace-tab .workspace-tab-body {
  padding: 16px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: auto;
  padding-bottom: 40vh;
}

.featured-image {
  max-width: 100%;
  max-height: 280px;
}

.hover-group {
  top: 20px;
  right: 20px;
}
.hover-group button {
  width: 50px;
  height: 50px;
  margin-left: auto;
}
.hover-group button span {
  display: none;
}
.hover-group button:hover {
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.hover-group button:hover span {
  display: inline;
}

.card-body .avatar-area .avatar-container .avatar {
  width: 150px;
  height: 150px;
}

textarea {
  resize: none;
}`],
})
export class PortalDizestSidebarWorkflowinfoComponent implements OnInit, OnDestroy {
    @Input() config = {};
    public data: any = {};
    public style: any = {};

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.data = this.config.workflows.active.data;
        this.style.image = {
            backgroundImage: `url(${this.data.image})`,
        };
        await this.service.render();
        await this.loadDeployLogs();
    }

    public async ngOnDestroy() { }

    public close() {
        this.config.wfinfo = null;
        this.service.render();
    }

    public isown() {
        return this.data.created_by === this.service.auth.session.id;
    }

    public descriptionEditable: boolean = false;

    public monaco: any = {
        value: '',
        language: 'markdown',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        scrollbar: {
            vertical: "hidden",
            handleMouseWheel: false,
        },
        minimap: {
            enabled: false
        }
    }

    public async toggleEditable() {
        this.descriptionEditable = !this.descriptionEditable;
        await this.service.render();
    }

    public showdown(text) {
        let converter = new showdown.Converter();
        return converter.makeHtml(text);
    }

    public async init(event: any) {
        let { editor } = event;

        let monaco_auto_height = async () => {
            const LINE_HEIGHT = 21;
            const el = editor.getDomNode();
            if (!el) return;
            let ui_line_counter = $(el).find('.margin-view-overlays .line-numbers').length;
            let counter = editor.getModel().getLineCount();
            let real_line = $(el).find('.view-lines .view-line').length;
            let height = real_line * LINE_HEIGHT;
            if (height < 105) height = 105;

            el.style.height = height + 'px';
            editor.layout();
            let resetcounter = 0;
            while (ui_line_counter != counter) {
                if (resetcounter > 20) break;
                ui_line_counter = $(el).find('.margin-view-overlays .line-numbers').length;
                counter = editor.getModel().getLineCount();
                real_line = $(el).find('.view-lines .view-line').length;
                real_line = real_line + 5;
                height = real_line * LINE_HEIGHT;

                if (height < 105) height = 105;
                el.style.height = height + 'px';
                editor.layout();
                resetcounter++;
            }
        }

        await monaco_auto_height();
        editor.onDidChangeModelDecorations(monaco_auto_height);
    }

    public upload: any = ((obj: any = {}) => {
        obj.image = async () => {
            try {
                this.data.image = await this.service.file.read({ type: 'image', accept: 'image/*', width: 40, quality: 1 });
                this.style.image = { 'background-image': 'url(' + this.data.image + ')' };
                await this.service.render();
            } catch (e) {
                toastr.error(e);
            }
        }
        return obj;
    })();

    public clear: any = ((obj: any = {}) => {
        obj.image = async () => {
            this.data.image = '';
            this.style.image = {};
            await this.service.render();
        }

        return obj;
    })();

    public async delete() {
        const res = await this.service.alert.show({
            title: "워크플로우 삭제",
            message: "이 워크플로우를 정말 삭제하시겠습니까?",
            status: "error",
            action: "삭제하기",
            actionBtn: "error",
            cancel: "취소",
        });
        if (!res) return;
        const body = {
            resource_id: this.data.id,
            resource_type: "workflow",
        };
        const { code } = await this.service.api("resources/delete", body);
        if (code !== 200) return await this.service.error("워크플로우 삭제 실패!");
        this.config.active = null;
        this.config.wfinfo = null;
        this.config.activeApp = null;
        this.config.workflows.active = null;
        this.config.switchNav('workflows');
        location.reload();
        await this.workflows.load();
        await this.service.render();
    }

    public deploy = null;
    public showDeploy() {
        this.deploy = {
            resource_id: this.data.id,
            resource_type: "workflow",
            request: "",
            access: "internal",
        };
        this.service.render();
    }

    public async deployItem() {
        if (!this.deploy) return;
        const body = this.service.copy(this.deploy);
        const { code } = await this.service.api("resources/deploy", body);
        if (code !== 200) return await this.service.error("워크플로우 배포 요청 실패!");
        await this.service.success("워크플로우 배포 요청 완료!");
        this.deploy = null;
        this.data.status = "pending";
        await this.loadDeployLogs();
    }

    public deployLogs = [];
    public async loadDeployLogs() {
        this.deployLogs = [];
        const { code, data } = await this.service.api("resources/deploy/log", { resource_id: this.data.id });
        if (code === 200) {
            this.deployLogs = data;
        }
        await this.service.render();
    }

    public displayUser(user) {
        try {
            return `${user.first_name} ${user.last_name}(${user.email})`;
        } catch {return "";}
    }
}

export default PortalDizestSidebarWorkflowinfoComponent;