import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.app');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-app',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.app/view.scss */
/* src/app/page.app/view.scss: no such file or directory */`],
})
export class PageAppComponent implements OnInit, DoCheck {
    public category = "";

    constructor(@Inject( Service) public service: Service) {
        if (!WizRoute.segment.category)
            return service.href("/app/list");
        this.category = WizRoute.segment.category;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(["developer", "admin"], "/main");
    }

    public async ngDoCheck() {
        if (!WizRoute.segment.category)
            return this.service.href("/app/list");
        this.category = WizRoute.segment.category;
    }
}

export default PageAppComponent;