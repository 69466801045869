import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.workflow');
import { OnInit } from "@angular/core";
import { ViewContainerRef } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Dizest } from "src/libs/portal/dizest/dizest";

@Component({
    selector: 'wiz-portal-krdc-workflow',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.workflow/view.scss */
.workflow-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 768px) {
  .workflow-layout {
    display: block;
  }
  .workflow-layout.tab-selected .workflow-nav {
    display: none;
  }
  .workflow-layout.tab-selected .workflow-sidebar-view {
    display: none;
  }
  .workflow-layout.tab-selected .workflow-content {
    height: 100%;
  }
  .workflow-layout .apps-click {
    display: none !important;
  }
}

.workflow-nav {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .workflow-nav {
    width: 100%;
  }
}
.workflow-nav .workflow-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 49%;
  right: 0;
  width: 16px;
  height: 64px;
  background: var(--wc-border);
  font-size: 16px;
  cursor: pointer;
  z-index: 1900;
}
.workflow-nav .workflow-toggle:hover {
  background: var(--wc-blue);
  color: #fff;
}
.workflow-nav .workflow-toggle.toggle-hide {
  position: fixed;
  left: 0;
}
.workflow-nav .view-content {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
@media (max-width: 768px) {
  .workflow-nav .view-content {
    width: 100%;
  }
}
.workflow-nav .view-content .view-header {
  padding: 0;
  padding-top: 0;
  background-color: var(--wc-blue);
  color: #fff;
}
.workflow-nav .view-content .view-header .view-title {
  display: flex;
  padding: 16px 16px;
  justify-content: center;
}
.workflow-nav .view-content .view-header .view-title img {
  height: 38px;
  margin: 0 auto;
}
.workflow-nav .view-content .view-header .clicks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.workflow-nav .view-content .view-header .clicks a {
  display: block;
  width: 100%;
  color: #63667b;
  text-align: center;
}
.workflow-nav .view-content .view-header .clicks .click {
  font-family: "main-eb";
  cursor: pointer;
  padding: 8px;
  display: inline-block;
  flex: 1;
  position: relative;
  background-color: var(--wc-blue);
  color: #fff;
  font-size: 20px;
}
.workflow-nav .view-content .view-header .clicks .click .action-btn {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.workflow-nav .view-content .view-header .clicks .click:hover {
  background-color: var(--wc-blue-hover) !important;
}
.workflow-nav .view-content .view-header .clicks .click.active {
  background-color: #f8fafe;
}
.workflow-nav .view-content .view-header .clicks .click.active .action-btn {
  color: var(--wc-blue);
}
.workflow-nav .view-content .view-header .clicks .click.active:hover {
  background-color: #fff !important;
}
.workflow-nav .view-content .view-header .clicks .line {
  margin: 0.75rem 0;
  border-right: 1px solid #63667b;
}
.workflow-nav .view-content .view-body {
  height: 100%;
  flex: auto;
  border-right: 1px solid var(--wc-border);
  overflow: hidden;
}
@media (max-width: 768px) {
  .workflow-nav .view-content .view-body {
    border-right: none;
  }
}
@media (max-width: 768px) {
  .workflow-nav .workflow-toggle {
    display: none;
  }
}

.workflow-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
}
.workflow-content .view-content {
  flex: 1;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .workflow-plugin-viewer {
    display: none;
  }
}

.workflow-sidebar-view {
  height: 100%;
}

.workflow-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 42px;
  border-left: 1px solid var(--wc-border);
  background-color: #fff;
}
@media (max-width: 768px) {
  .workflow-sidebar {
    display: none;
  }
}
.workflow-sidebar .btn {
  display: block;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.workflow-sidebar .btn,
.workflow-sidebar .btn .hover-area {
  padding: 8px 12px;
}
.workflow-sidebar .btn .hover-area {
  display: none;
  height: 36px;
  right: 0;
  margin-top: -28px;
  min-width: 80px;
  background-color: var(--wc-blue);
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  padding-right: 0 !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.workflow-sidebar .btn .hover-area .sidemenu-icon {
  width: 42px;
  text-align: center;
}
.workflow-sidebar .btn:hover .hover-area {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1000;
  right: 0;
}
.workflow-sidebar .btn:hover .hover-area span {
  margin-left: auto;
  margin-right: 4px;
}

.drive-view {
  height: 100%;
  width: 100%;
  overflow: hidden;
}`],
})
export class PortalKrdcWorkflowComponent implements OnInit {
    public dizest: Dizest;

    public shortcuts: any = [];

    constructor(@Inject( Service)         public service: Service,@Inject( ViewContainerRef    )         public viewContainerRef: ViewContainerRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/main");

        this.dizest = new Dizest(this, "krdc");
        await this.bindShortcuts();
        await this.workflows.load();
        await this.apps.load();
    }

    public active: any;
    public activeApp = null;
    public scope = () => {
        return this;
    }
    public workflows = {
        data: [],
        query: {
            page: 1,
            start: 0,
            end: -1,
        },
        open: async (item) => {
            this.active = null;
            await this.service.render();
            this.active = item;
            this.switchNav('apps');
        },
        load: async (page = null, text = "") => {
            if (page === null) page = 1;
            this.workflows.query.page = page;
            const body = {
                page,
                text,
            };
            const { code, data } = await wiz.call("load_workflows", body);
            if (code !== 200) return await this.service.error("워크플로우 목록 불러오기 실패");
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.workflows.query.start = startpage;
            this.workflows.query.end = lastpage;
            this.workflows.data = rows;
            await this.service.render();
        },
        create: async (body) => {
            await this.service.loading.show();
            const { code, data } = await wiz.call("create_workflows", body);
            await this.service.loading.hide();
            if (code !== 200) {
                await this.service.error("워크플로우 생성 중 에러가 발생하였습니다.");
                return false;
            }
            await this.service.success("워크플로우를 생성하였습니다.");
            this.workflows.data.splice(0, 0, data);
            await this.service.render();
            return true;
        },
    };

    public apps = {
        data: [],
        scope: () => {
            return this;
        },
        query: {
            page: 1,
            start: 0,
            end: -1,
        },
        load: async (page = null, text = "") => {
            if (page === null) page = 1;
            this.apps.query.page = page;
            const body = {
                // page,
                text,
            };
            const { code, data } = await wiz.call("load_apps", body);
            if (code !== 200) return await this.service.error("앱 목록 불러오기 실패");
            this.apps.data = data;
            await this.service.render();
        },
    };

    public view: any = {
        nav: 'workflows',
        hideNav: false,
        plugin: null
    };

    public async switchNav(nav: string) {
        this.view.nav = nav;
        await this.service.render();
    }

    public async toggleNav() {
        this.view.hideNav = !this.view.hideNav;
        await this.service.render();
    }

    public statusbar: any = {
        onLoad: async () => {
            let title = "";
            try {
                title = `[${this.active.category}] ${this.active.name} / ${this.active.description}`;
            } catch { }
            await this.statusbar.setTitle(title, "fa-solid fa-hdd");
            await this.statusbar.alert.info('dizest started', 2000);
            await this.service.render();
        }
    };

    public async bindShortcuts() {
        this.shortcuts.push({
            key: ["cmd + s", "ctrl + s"],
            preventDefault: true,
            command: async () => {
                if (!this.workflows.active) return;
                if (this.workflows.active.data.created_by !== this.service.auth.session.id) return;

                let workflow = this.workflows.active;
                let res = await workflow.update();
                if (!res) {
                    await this.statusbar.alert.error('An error occurred while saving', 3000);
                    return;
                }
                await this.statusbar.alert.info('update workflow', 1000);
            }
        });

        for (let i = 0; i < this.shortcuts.length; i++)
            this.shortcuts[i].allowIn = ['TEXTAREA', 'INPUT', 'SELECT'];

        await this.service.render();
    }

}

export default PortalKrdcWorkflowComponent;