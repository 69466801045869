import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.menu.workflows');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Dizest } from "src/libs/portal/dizest/dizest";

@Component({
    selector: 'wiz-portal-dizest-menu-workflows',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.dizest.menu.workflows/view.scss */
select.form-select.text-muted {
  color: rgba(var(--tblr-muted-rgb), 0.6) !important;
}

label.form-check-label {
  cursor: pointer;
}

.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.app .app-search {
  display: flex;
  padding: 14px 8px;
  border-bottom: 1px solid var(--wc-border);
  height: 50px;
  background: var(--wc-blue-light);
}
.app .app-search .btn,
.app .app-search .form-control {
  height: 28px;
}
.app .app-list {
  flex: auto;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 120px;
}
.app .app-list .no-items {
  font-size: 18px;
  font-weight: 1000;
  color: #555;
}
.app .app-list .no-items .btn.btn-primary {
  border-radius: 32px;
}
.app .app-list .no-items i {
  font-size: 48px;
  color: var(--wc-blue);
}
.app .app-list .app-category {
  background: var(--wc-light);
  color: var(--wc-text);
  padding: 4px 12px;
  border-bottom: 1px solid var(--wc-border);
  font-size: 14px;
  line-height: 1.2;
}
.app .app-list .app-info {
  border-bottom: 1px solid var(--wc-border);
  line-height: 1;
  padding: 8px 16px;
  cursor: grab;
  display: flex;
  align-items: center;
}
.app .app-list .app-info :active {
  cursor: grabbing;
}
.app .app-list .app-info.active {
  background: var(--wc-blue-light);
}
.app .app-list .app-info .title-area {
  flex: auto;
}
.app .app-list .app-info .title-area .app-header {
  font-size: 14px;
  font-weight: 1000;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  background: transparent;
}
.app .app-list .app-info .title-area .app-namespace {
  font-size: 12px;
}
.app .app-list .app-info:hover {
  background: var(--wc-blue-light);
}
.app .app-list .app-info .btn {
  height: 16px;
}
.app .app-list .app-info .btn i {
  font-size: 12px;
  width: 12px;
  height: 12px;
}

.app-menu {
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.app-menu .menu-item {
  background: var(--wc-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  flex: 1;
  color: #fff;
  cursor: pointer;
}
.app-menu .menu-item i {
  width: 14px;
  height: 14px;
}
.app-menu .menu-item:hover {
  color: #F8F9FF;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 450px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 450px;
  max-width: 450px;
}`],
})
export class PortalDizestMenuWorkflowsComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() dizest: Dizest;
    @Input() config: any = {};
    public categories = [null, null, null];

    public keyword: string = '';

    public async ngOnInit() {
        await this.service.init();
        this.categories[0] = await this.getCategory("ROOT");
        await this.bookmark();
        await this.service.render();
    }

    public filter = {
        my: false,
        public: false,
        bookmark: false,
    };

    public cache = {};
    public async getCategory(categoryId) {
        if (this.cache[categoryId]) return this.cache[categoryId];
        const { code, data } = await this.service.api(`categories/${categoryId}`);
        if (code !== 200) return [];
        const children = data
            .map(({ id, name }) => ({ id: id, name: name, count: 0, open: false }))
            .sort((a, b) => a.name - b.name);
        this.cache[categoryId] = children;
        return this.cache[categoryId];
    }

    public async setCategory(idx, id) {
        await this.service.render();
        if (![1, 2].includes(idx)) return;
        const children = await this.getCategory(id);
        this.categories[2] = null;
        this.categories[idx] = children;
        await this.service.render();
    }

    public match(item: any, keyword: string) {
        if (item.name.toLowerCase().indexOf(keyword.toLowerCase()) < 0) return false;
        if (this.filter.my && item.created_by !== this.service.auth.session.id) return false;
        if (this.filter.public && item.access !== "public") return false;
        if (this.filter.bookmark && !this.bookmark_list.includes(item.id)) return false;
        return true;
    }

    public async search(keyword: any = null) {
        if (keyword !== null) this.keyword = keyword;
        if (this.filter.my && item.created_by !== this.service.auth.session.id) return false;
        if (this.filter.public && item.access !== "public") return false;
        if (this.filter.bookmark && !item.bookmark) return false;
        await this.service.render();
    }

    public bookmark_list: string[] = [];

    public async bookmark() {
        const body = {};
        body.resource_type = "workflow";
        const { code, data } = await this.service.api("resources/bookmark", body, { type: "get" });
        if (code === 200) {
            this.bookmark_list = data.rows.map(row => row.resource_id);
        }
    }

    public async onFileSelected(event) {
        this.service.file.resize(event.target.files[0], 150)
            .then(base64String => {
                this.createModal.image = base64String;
            });
    }

    public createModal = false;
    public showCreateModal = () => {
        this.createModal = {
            category: [null, null, null],
            group: "preprocessing",
            name: "",
            description: "",
            image: null,
        };
        this.service.render();
    }
    public async create() {
        if (this.createModal.name.replace(/\s/g, "").length === 0) return;
        const body = this.service.copy(this.createModal);
        body.category = body.category.findLast(value => value !== null);
        if (!body.category) return;

        const res = await this.config.create(body);
        if (res) {
            this.createModal = false;
            this.service.render();
        }
    }

    public async open(item) {
        this.config.open(item);
    }

    public async delete(item: any) {
        console.log("workflow delete", item);
    }

}

export default PortalDizestMenuWorkflowsComponent;